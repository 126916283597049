import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services ';
import Layout from './Layout';
import Placement from './pages/Placement';
import Bengaluru from './Branches/bengaluru';
import Hyderabad from './Branches/hyderabad';
import DiplomaInBeverageManagement from './courses/beverageManagement';
import DiplomaInFoodAndBeverageService from './courses/beverageService';
import AdvancedMolecularMixology from './courses/mixology';
import StandardBartendingCourse from './courses/bartendingCourse'
import BasicBarCourse from './courses/barCourse';
import WeekendCourse from './courses/weekendCourse';
import ContactUs from './pages/ContactUs';
import Placements from './pages/Placements';
import FormPage from './pages/FormPage';

const App = () => {
  return (
    <>
        <FormPage/>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="placement" element={<Placement />} />
          <Route path="services" element={<Services />} />
          <Route path="placements" element={<Placements />} />
          <Route path="Diploma-In-Beverage-Management" element={<DiplomaInBeverageManagement/>} />
          <Route path="Diploma-In-Food-And-Beverage-Service" element={<DiplomaInFoodAndBeverageService/>} />
          <Route path="Advanced-Molecular-Mixology" element={<AdvancedMolecularMixology/>} />
          <Route path="Standard-Bartending-Course" element={<StandardBartendingCourse/>} />
          <Route path="Basic-Bar-Course" element={<BasicBarCourse/>} />
          <Route path="Weekend-Course" element={<WeekendCourse/>} />
          <Route path="bengaluru" element={<Bengaluru/>} />
          <Route path="hyderabad" element={<Hyderabad/>} />
          <Route path="contact_Us" element={<ContactUs/>} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
