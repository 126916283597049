import React from 'react';
import '../../App.css';
import Fire_Flair from "../../Assets/Bartending/Fire_Flair.jpeg";
import Flair from "../../Assets/Bartending/Flair.jpeg";
import Juggling from "../../Assets/Bartending/Juggling.jpeg";
import Mixology from "../../Assets/Bartending/Mixology.jpeg";
import Flair1 from "../../Assets/Bartending/Flair_1.jpeg";
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import Placement from './Placement';
import Skills from './Skills';

export default function Home() 
{
  const items = 
  [
    <img style={{width:"95%", height : "auto"}} src={Fire_Flair} alt="Fire_Flair" />,
    <img style={{width:"95%", height : "auto"}}  src={Flair} alt="Flair" />,
    <img style={{width:"95%", height : "auto"}}  src={Juggling} alt="Juggling" />,
    <img style={{width:"95%", height : "auto"}}  src={Mixology} alt="Mixology" />,
    <img style={{width:"95%", height : "auto"}}  src={Fire_Flair} alt="Fire_Flair" />,
    <img style={{width:"95%", height : "auto"}}  src={Flair1} alt="Flair" />
  ];

  const responsive =
  {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  }   
return (
  <>
  <div className='MainimageSlider'>
      <div className='ImageSlider'>
        <AliceCarousel 
            infinite 
            autoPlayInterval="2000" 
            disableDotsControls 
            disableButtonsControls 
            items={items} 
            responsive={responsive}
            autoPlay />
        </div>
      </div>
        <Skills/>
        <Placement/>
    </>
    )
}
