import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';

function FormPage() 
{
    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_slgc36n', 'template_gi0nget', form.current, '_7VE3r4OEKyn5g4u7')
      .then((result) => {
          console.log(result.text);
          alert("done!")
      }, (error) => {
          console.log(error.text);
      });
  };

  function openNav()
  {
    document.getElementById("myForm").style.display = "block";
    document.getElementById("myForm").style.width= "400px";
  }

  function closeNav() 
  {
    document.getElementById("myForm").style.display = "none";
    document.getElementById("myForm").style.width = "0";
  }

    return<>
        <div className="icon-bar">
          <span className="open-button" onClick={()=>openNav()}>Enquiry</span>
          <a href="#" rel="noreferrer" className="facebook"><i className="fa fa-facebook"></i></a> 
          <a href="https://www.instagram.com/universal_bartending_academy_/" rel="noreferrer" target='_blank' className="instagram"><i className="fa fa-instagram"></i></a> 
          <a href='mailto:universalbartendingacademy@gmail.com' rel="noreferrer" className="email"><i className="fa fa-envelope"></i></a> 
          <a href="tel:8867169696" rel="noopener" className="phone"><i className="fa fa-phone"></i></a>
        </div>

        <div className="form-popup" id="myForm">
        <a href="javascript:void(0)" className="closebtn" onClick={()=>closeNav()}>×</a>
          <div className='formMainDiv'>
                <p className="formHeader">Get In Touch</p>
                <div className="form">
                  <form ref={form} onSubmit={sendEmail}>
                    <label className="formLabel">Name :</label>
                    <input className="formInput" name='name'></input>
                    <label className="formLabel">Email :</label>
                    <input className="formInput" name='email'></input>
                    <label className="formLabel">Phone Number :</label>
                    <input className="formInput" name='phno'></input>
                    <label className="formLabel">City :</label>
                    <input className="formInput" name='city'></input>
                    <label className="formLabel">Requirement :</label>
                    <input className="formInput" name='requirment'></input>
                    <input type="submit" value="Submit" className="formButton" />
                  </form>
                </div>   
          </div>
       </div>
    </>
}

export default FormPage;