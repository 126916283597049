import React from 'react';
import '../../App.css';
import Course_6 from '../../Assets/CourseImages/Course6.jpeg';
import AllContact from '../pages/AllContacts';
 
export default function weekendCourse() {
  return <div id='CoursesScreen'>
  <div className='courseCards1'>
      <div className='courseCards1Img'>
          <img alt='imagr' src={Course_6}></img>
      </div>
      <div className='courseCards1Content'>
        <h2 className='coursesHeader'>Weekend Course</h2>
          <p className='courseCardsDes'>Weekend course provides and optional interest to the working professionals of various 
                        streams other than hospitality in getting  to know about the spirits and cocktail 
                        making techniques</p>
    </div>
    </div>
    <div className='coursesCards'>
      <div className='cards'>
          <i class="far fa-clock"></i>                        
          <h3>DURATION</h3>
          <p>2 Days</p>
      </div>
      <div className='cards'>
      <i class="fa-solid fa-certificate"></i>                  
       <h3>CERTIFICATE</h3>
          <p>We Provide International Course Certificate</p>
      </div >
      <div className='cards'>
      <i class="fa-solid fa-passport"></i>
          <h3>INTERNATIONAL PLACEMENT</h3>
          <p>We Offer Placements In National And International</p>
      </div>
    </div>
    {/* <div className='CourseContent' >
    <h2 className='coursesTeach'>We teach :</h2>
      <ul>
        <li> Basic spirit knowledge </li>
        <li>Making extensive cocktails and mock tails</li> 
        <li>Fire flairing and juggling </li>
        <li>Inventory</li>
        </ul>
    </div> */}
  <div>
    <AllContact/>
  </div>
 </div>
}
