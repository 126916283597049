import React from "react";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import logo from '../../Assets/Bartending/only logo.png';


class AllContact extends React.Component
{
    render()
    {
        return<div className="AllContactScreen">
                <h1 className="getInTouch">Get In Touch</h1>
                <div className='AllcontactScreenDiv'>
                    {this.buildBangaloreContact()}
                    {this.buildHyderbadContact()}
                </div> 
            </div>       
    }

    
    buildBangaloreContact()
    {
        return <div className='AllContactSections'>
                    <img src={logo} className="contactImg" alt="logoImage" ></img>
                    <h2>UNIVERSAL BARTENDING ACADEMY</h2>
                    <h3>Explore The Profession With Us</h3> 
                    <h4>We are expert in training and placing students in India and Abroad</h4>
                </div>
    }

    buildHyderbadContact()
    {
        return <div className='AllContactSection'>
                    <div className="AlllocationAddress" id="bengaluruAddress">
                        <p className="locationName">Bengaluru</p>
                        <p className="AlllocationIcon"><LocationOnOutlinedIcon/></p>
                        <p className="AlllocationAdr">1/6,Opposite Karimariyamman Arch Metro Pillar No 239,near Magadi Road Metro Station Bangaluru - 560023.<br/></p>
                        <div className="Alllocation_Email">
                            <p className="AlllocationIcon"><MailOutlinedIcon/></p>
                            <p className="AlllocationEmail">universalbartendingacademy@gmail.com</p>
                        </div>
                        <div className="Alllocation_Phone">
                            <p className="AlllocationIcon"><CallOutlinedIcon/></p>
                            <p className="AlllocationEmail">+91 8867169696 /+91 8867269696</p>
                        </div>
                    </div>
                    <div className="AlllocationAddress" id="hyderabadAddress">
                        <p className="locationName">Hyderabad</p>
                        <p className="AlllocationIcon"><LocationOnOutlinedIcon/></p>
                        <p className="AlllocationAdr">16-11-19/2/5, behind Yes Bank, Saleem Nagar Colony, Malakpet Extension, New Malakpet, Hyderabad, Telangana - 500036.<br/></p>
                        <div className="Alllocation_Email">
                            <p className="AlllocationIcon"><MailOutlinedIcon/></p>
                            <p className="AlllocationEmail">ubahyderbad@gmail.com</p>
                        </div>
                        <div className="Alllocation_Phone">
                            <p className="AlllocationIcon"><CallOutlinedIcon/></p>
                            <p className="AlllocationEmail">+91 9059210536 /+91 9059210836</p>
                        </div>
                    </div>
    </div>
}
}   
export default AllContact;


