import React from 'react';
import '../../App.css';
import Marriot from "../../Assets/National_Placements/Marriot.png";
import Hyatt from  "../../Assets/National_Placements/Hyatt-Logo-1990.jpg";
import Hilton from "../../Assets/National_Placements/Hilton.png";
import Raddsion from "../../Assets/National_Placements/Radisson-Logo.png";
import Accor from "../../Assets/National_Placements/Accor_logo.png";
import HardRock from "../../Assets/National_Placements/Hard_Rock.png";
import Zero40 from "../../Assets/National_Placements/Zero40.jpg";
import Leona from "../../Assets/National_Placements/Leona_resort.png";
import downingstreet from "../../Assets/National_Placements/10downingstreet.jpg";
import Arjaan from "../../Assets/InterNational_Placements/Arjaan.png";
import Belgianbeer from "../../Assets/InterNational_Placements/Belgianbeer.png";
import Four_Seasons from "../../Assets/InterNational_Placements/Four_Seasons.png";
import oneonly from "../../Assets/InterNational_Placements/oneonly.png";
import Spindrift from "../../Assets/InterNational_Placements/Spindrift.png";
import starwood from "../../Assets/InterNational_Placements/starwood.jpg";
import Sunsand from "../../Assets/InterNational_Placements/Sunsand.png";
import Tapasbar from "../../Assets/InterNational_Placements/Tapasbar.jpg";
import vhotel from "../../Assets/InterNational_Placements/vhotel.jpg";
import whotel from "../../Assets/InterNational_Placements/whotel.png";
import AllContact from './AllContacts';
import maldives from '../../Assets/Placements_Country/maldives.jpg';
import unitedkingdom from '../../Assets/Placements_Country/united-kingdom.jpg';
import australia from '../../Assets/Placements_Country/australia.jpeg';
import canada from '../../Assets/Placements_Country/canada.jpg';
import croatia from '../../Assets/Placements_Country/Croatia.jpg';
import dubai from '../../Assets/Placements_Country/Dubai.jpg';
import france from '../../Assets/Placements_Country/France.jpg';
import qatar from '../../Assets/Placements_Country/Qatar.jpg';

const Placements = ()=>
{
    return(
    <div className='PlacementsScreen'>
        <h1 className='serviceTitle'>We Offer International Placements In 32+ Countries</h1><div class="countryCard">
            <div class="card">
        
                <img alt='maldives' src={maldives}></img>
                <h3>Maldives</h3>
            </div>
            <div class="card">
                <img alt='unitedkingdom' src={unitedkingdom}></img>
                <h3>United Kingdom</h3>
            </div>
            <div class="card">
                <img alt='australia' src={australia}></img>
                <h3>Australia</h3>
            </div>
            <div class="card">
                <img alt='canada' src={canada}></img>
                <h3>Canada</h3>
            </div>
            <div class="card">
                <img alt='croatia' src={croatia}></img>
                <h3>Croatia</h3>
            </div>
            <div class="card">
                <img alt='imadubaige' src={dubai}></img>
                <h3>Dubai</h3>
            </div>
            <div class="card">
                <img alt='france' src={france}></img>
                <h3>France</h3>
            </div>
            <div class="card">
                <img alt='qatar' src={qatar}></img>
                <h3>Qatar</h3>
            </div>
        </div>
            <p className='placementTitle'>We provide 100% placements in star hotels, pubs, clubs, discotheque, casinos 
                        and renowned stand alone bar and restaurants</p>
            <div id="NationalPacement_Img">
                <h2 className='placementSubTitle'>National Placement</h2>
                <div className="slide-track">
                    <img src={Marriot} alt=" "/>
                    <img src= {Hyatt}  alt=""/>
                    <img src= {Hilton}  alt=""/>
                    <img src={Raddsion} alt=""/>
                    <img src= {Accor}  alt=""/>
                    <img src={HardRock}  alt=""/>
                    <img src= {Zero40}  alt=""/>
                    <img src={Leona}  alt=""/>
                    <img src={downingstreet}  alt=""/>
                </div>
            </div>
            <div id = "InternationalPacement_Img">
                <h2 className='placementSubTitle'>International Placement</h2>
                <div className="slide-track-1">                   
                    <img src={Arjaan} alt=" "/>
                    <img src= {Belgianbeer}  alt=""/>
                    <img src= {Four_Seasons}  alt=""/>
                    <img src={oneonly} alt=""/>
                    <img src={Spindrift} alt=" "/>
                    <img src= {starwood}  alt=""/>
                    <img src= {Sunsand}  alt=""/>
                    <img src={Tapasbar} alt=""/>
                    <img src= {vhotel}  alt=""/>
                    <img src={whotel} alt=""/>
                </div>
            </div>
            <div>
                <AllContact/>
            </div>
</div>
    );
}

export default Placements;