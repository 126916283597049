import { menuItems } from '../menuItems';
import MenuItems from './MenuItems';
import { GiHamburgerMenu } from 'react-icons/gi';
import { AiOutlineClose } from 'react-icons/ai';
import { slide as Menu } from 'react-burger-menu';


const Navbar = () => {

  return (
    <>
    <nav>
      <ul className="menus">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return (
            <MenuItems
              items={menu}
              key={index}
              depthLevel={depthLevel}
            />
          );
        })}
      </ul>
    </nav>
    <nav id = "HamburgerBar">
						<Menu noOverlay right width={ 240 }
							customBurgerIcon={<GiHamburgerMenu className="burgerMenuIcons" />}
							customCrossIcon={<AiOutlineClose  id="close" className="burgerMenuIcons" />} 
							>
                <ul className="menus">
                {menuItems.map((menu, index) => {
                  const depthLevel = 0;
                  return (
                    <MenuItems
                      items={menu}
                      key={index}
                      depthLevel={depthLevel}
                    />
                  );
                })}
              </ul>
						</Menu>
			</nav>
    </>
  );
};

export default Navbar;
