import React from 'react';
import '../../App.css';
import Course_new1 from '../../Assets/CourseImages/Course_new1.jpeg';
import AllContact from '../pages/AllContacts';


export default function beverageManagement() {
  return <div id='CoursesScreen'>
                <div className='courseCards1'>
                    <div className='courseCards1Img'>
                        <img alt='imagr' src={Course_new1}></img>
                    </div>
                    <div className='courseCards1Content'>
                      <h2 className='coursesHeader'>Diploma In Beverage Management</h2>
                        <p className='courseCardsDes'>Beverage management addresses the proper management of beverages within a hospitality operation, 
                        and its goal is to optimize revenue through proper purchasing and inventory processes, 
                        and to enhance the operation through menu development and product selection.</p>
                        <h2 className='coursesTeach'>We teach :</h2>
                        <ul>
                            <li> Spirit manufacturing techniques that include( Whisky,vodka,rum,Gin,tequila and brandy )</li>
                            <li>Fermented beverages (Wine,Beer,cider,perry and Mead and others )</li> 
                            <li>Bar management</li>
                            <li>Inventory and register maintenance </li>
                            <li>Excise inventories </li>
                            <li>Stock maintenance</li>
                        </ul>
                  </div>
                  </div>
                  <div className='coursesCards'>
                    <div className='cards'>
                        <i class="far fa-clock"></i>                        
                        <h3>DURATION</h3>
                        <p>1 Years</p>
                    </div>
                    <div className='cards'>
                    <i class="fa-solid fa-certificate"></i>                  
                     <h3>CERTIFICATE</h3>
                        <p>We Provide International Course Certificate</p>
                    </div >
                    <div className='cards'>
                    <i class="fa-solid fa-passport"></i>
                        <h3>INTERNATIONAL PLACEMENT</h3>
                        <p>We Offer Placements In National And International</p>
                    </div>
                  </div>
                <div>
                  <AllContact/>
                </div>
               </div>
}