import React from 'react';
import '../../App.css';
import hydBar from "../../Assets/Branches/hyderbad/hydBar.jpg";
import hydBarCounter from '../../Assets/Branches/hyderbad/hydBarCounter.jpg';
import hydClass from '../../Assets/Branches/hyderbad/hydClass.jpg';
import hydClassroom from '../../Assets/Branches/hyderbad/hydClassroom.jpg';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

export default function hyderabad() {
  return <div id="branchScreen">
  <h2 className='branchTitle'>Hyderabad Campus</h2>
  <div className="branch-slide-track">
        <img src= {hydClass}  alt="hydClass"/>
        <img src= {hydClassroom}  alt="hydClassroom"/>
        <img src= {hydBar}  alt="hydBar"/>
        <img src= {hydClass}  alt="hydClass"/>
        <img src= {hydBarCounter}  alt="hydBarCounter"/>
        <img src= {hydClassroom}  alt="hydClassroom"/>
        <img src= {hydClass}  alt="hydClass"/>
        <img src= {hydClassroom}  alt="hydClassroom"/>
        <img src= {hydBar}  alt="hydBar"/>
        <img src= {hydClass}  alt="hydClass"/>
        <img src= {hydBarCounter}  alt="hydBarCounter"/>
        <img src= {hydClassroom}  alt="hydClassroom"/>
    </div>
    <div className='BranchContactScreen'>
        <h1>Contact Us</h1>
        <div className='BranchDiv'>
            <h3>UNIVERSAL BARTENDING ACADEMY HYDERABAD</h3>
            <div className="BranchlocationAddress">
                <p className="BranchlocationIcon"><LocationOnOutlinedIcon/></p>
                <p className="BranchlocationAdr">Metro Station Musarambagh, 16-11-19/2/5, behind Yes Bank, Saleem Nagar Colony, Malakpet Extension, New Malakpet, Hyderabad, Telangana 500036</p>
            </div>
            <div className="Branchlocation_Email">
                <p className="BranchlocationIcon"><MailOutlinedIcon/></p>
                <p className="BranchlocationEmail">ubahyderbad@gmail.com</p>
            </div>
            <div className="BranchBranchlocation_Phone">
                <p className="BranchlocationIcon"><CallOutlinedIcon/></p>
                <p className="BranchlocationEmail">+91 9059210536 /+91 9059210836</p>
            </div>
        </div>
        <div className='BranchembeddedMap'>
            <iframe className='BranchubaMap' title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121814.34806510647!2d78.3630182883178!3d17.42625681906061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99a7f3632149%3A0x381a3f52d1779a80!2sUniversal%20Bartending%20Academy%20Hyderbad!5e0!3m2!1sen!2sin!4v1693728645668!5m2!1sen!2sin"></iframe>
        </div>
    </div>
</div>
}
