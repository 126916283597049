import React from 'react';
import '../../App.css';
import bngBarCounter from '../../Assets/Branches/banglore/bngBarCounter.jpeg';
import bngClassRoom from '../../Assets/Branches/banglore/bngClassRoom.jpeg';
import bngOffice from '../../Assets/Branches/banglore/bngOffice.jpeg';
import bngBar   from '../../Assets/Branches/banglore/bngBar.jpeg';
import bngClass from '../../Assets/Branches/banglore/bngclass.jpeg';
import bngFlair from '../../Assets/Branches/banglore/bngflairing.jpeg';
import bngFlair1 from '../../Assets/Branches/banglore/bngflairing1.jpeg';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

export default function bengaluru() {
  return <div id="branchScreen">
  <h2 className='branchTitle'>Bengaluru Campus</h2>
  <div className="branch-slide-track">
      <img src={bngBarCounter} alt=" bngBarCounter"/>
      <img src= {bngClassRoom}  alt="bngClassRoom"/>
      <img src= {bngOffice}  alt="bngOffice"/>
      <img src={bngBar} alt="bngBar"/>
      <img src= {bngClass}  alt="bngClass"/>
      <img src={bngFlair} alt=" bngFlair"/>
      <img src= {bngFlair1}  alt="bngFlair1"/>
      <img src={bngBarCounter} alt=" bngBarCounter"/>
      <img src= {bngClassRoom}  alt="bngClassRoom"/>
      <img src= {bngOffice}  alt="bngOffice"/>
      <img src={bngBar} alt="bngBar"/>
  </div>
  <div className='BranchContactScreen'>
        <h1>Contact Us</h1>
        <div className='BranchDiv'>
            <h3>UNIVERSAL BARTENDING ACADEMY BENGALURU</h3>
            <div className="BranchlocationAddress">
                <p className="BranchlocationIcon"><LocationOnOutlinedIcon/></p>
                <p className="BranchlocationAdr">1/6,Opposite Karimariyamman Arch Metro Pillar No 239, near Magadi Road Metro Station Bangaluru - 560023</p>
            </div>
            <div className="Branchlocation_Email">
                <p className="BranchlocationIcon"><MailOutlinedIcon/></p>
                <p className="BranchlocationEmail">universalbartendingacademy@gmail.com</p>
            </div>
            <div className="BranchBranchlocation_Phone">
                <p className="BranchlocationIcon"><CallOutlinedIcon/></p>
                <p className="BranchlocationEmail">+91 8867169696 /+91 8867269696</p>
            </div>
        </div>
        <div className='BranchembeddedMap'>
            <iframe className='BranchubaMap' title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.944453676535!2d77.55104817459356!3d12.975404587340323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d8d101bbe33%3A0xd1f0d9654b49a9bc!2sUniversal%20Bartending%20Academy!5e0!3m2!1sen!2sin!4v1687790477857!5m2!1sen!2sin"></iframe>
        </div>
    </div>
</div>
}
