import { Outlet } from 'react-router-dom';
import Header from './Header';

const Layout = () => {

  
//   const handleClick = () => {
//     document.getElementById("react-burger-cross-btn").click();
    
// };



  return (
    <div>
      <Header />
      <div className="content" >
        <Outlet/>
      </div>
    </div>
  );
};

export default Layout;
