export const menuItems = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About',
    url: 'about',
  },
  {
    title: 'Courses',
    url: '/',
    submenu: [
     {
       title: 'Diploma In Beverage Management',
       url: 'Diploma-In-Beverage-Management',
      },
      {
        title: 'Diploma In Food And Beverage Service',
        url: 'Diploma-In-Food-And-Beverage-Service',
       },
       {
        title: 'Advanced Molecular Mixology',
        url: 'Advanced-Molecular-Mixology',
       },
       {
        title: 'Standard Bartending Course',
        url: 'Standard-Bartending-Course',
       },
       {
        title: 'Basic Bar Course',
        url: 'Basic-Bar-Course',
       },
       {
        title: 'Weekend Course',
        url: 'Weekend-Course',
       },
    ]
  },
  {
    title: 'Placements',
    url: 'placements',
  },
  {
    title: 'Services',
    url: 'services',
  },
  {
    title: 'Branches',
    url: '/',
    submenu: [
      {
        title: 'Bengaluru',
        url: 'bengaluru',
       },
       {
         title: 'Hyderabad',
         url: 'hyderabad',
        },
    ]
  },
  {
     title: 'Contact Us',
     url: 'contact_Us',
  },
  // {
  //   title: 'Services',
  //   url: '/services',
  //   submenu: [
  //     {
  //       title: 'web design',
  //       url: 'web-design',
  //     },
  //     {
  //       title: 'web development',
  //       url: 'web-dev',
  //       submenu: [
  //         {
  //           title: 'Frontend',
  //           url: 'frontend',
  //         },
  //         {
  //           title: 'Backend',
  //           submenu: [
  //             {
  //               title: 'NodeJS',
  //               url: 'node',
  //             },
  //             {
  //               title: 'PHP',
  //               url: 'php',
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       title: 'SEO',
  //       url: 'seo',
  //     },
  //   ],
  // },
  // {
  //   title: 'About',
  //   url: '/about',
  //   submenu: [
  //     {
  //       title: 'Who we are',
  //       url: 'who-we-are',
  //     },
  //     {
  //       title: 'Our values',
  //       url: 'our-values',
  //     },
  //   ],
  // },
];
