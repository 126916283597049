import React from 'react';
import '../../App.css';
import Course_new3 from '../../Assets/CourseImages/Course_new3.jpeg';
import AllContact from '../pages/AllContacts';

export default function mixology() {
  return <div id='CoursesScreen'>
  <div className='courseCards1'>
      <div className='courseCards1Img'>
          <img alt='imagr' src={Course_new3}></img>
      </div>
      <div className='courseCards1Content'>
        <h2 className='coursesHeader'>Advanced Molecular Mixology</h2>
          <p className='courseCardsDes'>Mixologists serve drinks, but bartenders serve customers.Mixologists can be employed by spirits
                        companies to create special concoctions featuring their spirits, but bartenders are only employed
                         by bars.Molecular mixology is a type of bartending, in which a number of processes and techniques 
                         are used, reminiscent to that of a science lesson. Molecular mixology involves using professional 
                         equipment like blowtorches, liquid nitrogen, foams and mists.</p>
                         <h2 className='coursesTeach'>We teach :</h2>
      <ul>
        <li>Spirit manufacturing techniques</li>
        <li> Molecular mixology </li> 
        <li>MolecularGastronomy </li>
        <li>Making syrups and liqueurs </li>
        <li>Gelling Agents and process </li>
      </ul>
    </div>
    </div>
    <div className='coursesCards'>
      <div className='cards'>
          <i class="far fa-clock"></i>                        
          <h3>DURATION</h3>
          <p>6 Months</p>
      </div>
      <div className='cards'>
      <i class="fa-solid fa-certificate"></i>                  
       <h3>CERTIFICATE</h3>
          <p>We Provide International Course Certificate</p>
      </div >
      <div className='cards'>
      <i class="fa-solid fa-passport"></i>
          <h3>INTERNATIONAL PLACEMENT</h3>
          <p>We Offer Placements In National And International</p>
      </div>
    </div>
  <div>
    <AllContact/>
  </div>
 </div>
}
