import Navbar from './Navbar';
import logo from '../Assets/Bartending/only logo.png';

const Header = () => {
  return (
    <header>
      <div className="nav-area">
          <img src={logo} className='logo' alt="logoImage" ></img>
          <h4 className='universalHeader'>UNIVERSAL</h4>
          <h4 className='bartendingHeader' >BARTENDING</h4>
          <h4 className='academyHeader' >ACADEMY</h4>
        <Navbar />
      </div>
    </header>
  );
};

export default Header;
