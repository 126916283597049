import React from 'react';
import '../../App.css';
import whyusImg from "../../Assets/whyus/Study-Abroad.jpg"
import AllContact from './AllContacts';

export default function About() {
  return (
    <>
    <div className = 'AboutScreen'>
      <div className='about_us'>
        <h1 className='AboutScreenHeader'>About Us</h1>
        <p className='about_us_p'>We are universal bartending specialised in providing exceptional bar services and 
            training that involves spirit manufacturing techiniques in detailed theories and flair 
            by masters in the industry.We are consistently delivering professionals in hospitality field for years.</p>
      </div>
      <div className='why_us'>
                <div className='why_usContent'>
                    <h1 className='whyUsHeader'>Why Us</h1>
                    <p id='why_usContent_p'>
                        We have intellectual client tie ups with over 32+ countries and only academy in India providing with 
                        international placements for its students We make sure students gets 100% placed in recognised 
                        beverage outlets and star hotels both in India and internationally.
                    </p>
                </div>
                <div className='why_usImg'>
                    <img alt='imag' src={whyusImg}></img>
                </div>
            </div>
            <AllContact/>
    </div>
    </>
  );
}
