

import React from "react";

class Skills extends React.Component
{
    render()
    {
        return <div className="skillDiv">
        <h1>What Do You Get!</h1>
                <div className='skillCards'>
                    <div className='skils'>
                        <i class="fa-solid fa-book-open-reader"></i>
                        <h3>KNOWLEDGE</h3>
                        <p>Get Knowledge With Internationally Trained Faculty</p>
                    </div>
                    <div className='skils'>
                        <i class="fa-solid fa-champagne-glasses"></i>                        
                        <h3>SKILLS</h3>
                        <p>Explore The Profession With Us In Flairing and Bar Work</p>
                    </div >
                    <div className='skils'>
                    <i class="fa-solid fa-user-tie"></i>
                        <h3>PROFESSIONAL</h3>
                        <p>We Provide Professional Training To Become Champion </p> 
                    </div>
                    <div className='skils'>
                        <i class="fa-solid fa-passport"></i>
                        <h3>JOB GURANTEE</h3>
                        <p>We Provide 100% National and International Placements</p>
                    </div>
                </div> 
                </div>   
    }

}
   


export default Skills;