import React from 'react';
import '../../App.css';
import Course_4 from '../../Assets/CourseImages/Course4.jpeg';
import AllContact from '../pages/AllContacts';

export default function bartendingCourse() {
  return <div id='CoursesScreen'>
  <div className='courseCards1'>
      <div className='courseCards1Img'>
          <img alt='imagr' src={Course_4}></img>
      </div>
      <div className='courseCards1Content'>
        <h2 className='coursesHeader'>Standard Bartending Course</h2>
          <p className='courseCardsDes'>Bartenders work directly with customers by mixing and serving drink orders. 
                        Their responsibilities include verifying age requirements, knowing alcohol pairing 
                        and tastes, knowing how to make traditional and classy drinks, processing payments,
                         managing inventory and cleaning bar supplies.</p>
                         <h2 className='coursesTeach'>We teach :</h2>
      <ul>
        <li> Professional bartending techniques </li>
        <li>Excellent flairing skills</li> 
        <li>Cocktails and mock tail making ( innovative&classic)</li>
        <li>Menu engineering</li>
        <li>Nutritional values of a drink </li>
        <li>Making inventories</li>
      </ul>
    </div>
    </div>
    <div className='coursesCards'>
      <div className='cards'>
          <i class="far fa-clock"></i>                        
          <h3>DURATION</h3>
          <p>4 Months</p>
      </div>
      <div className='cards'>
      <i class="fa-solid fa-certificate"></i>                  
       <h3>CERTIFICATE</h3>
          <p>We Provide International Course Certificate</p>
      </div >
      <div className='cards'>
      <i class="fa-solid fa-passport"></i>
          <h3>INTERNATIONAL PLACEMENT</h3>
          <p>We Offer Placements In National And International</p>
      </div>
    </div>
  <div>
    <AllContact/>
  </div>
 </div>
}
