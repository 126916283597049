import React from 'react';
import '../../App.css';
import AllContact from './AllContacts';

export default function Services () {
  return (
    <>
      {/* <h1 className='home'>Services</h1> */}
      <div className='ServicesScreen'>
             <h1 className='serviceTitle'>We Offer Our Services For</h1>
             <div className='serviceCard'>
                <div className='Eventservices1'>
                    <h2>Event Services</h2>
                </div>
                <div className='Eventservices2'>
                    <h2>Outsourcing Bartenders</h2>
                </div>
                <div className='Eventservices3'>
                    <h2>Molecular Services</h2>
                </div>
                <div className='Eventservices4'>
                    <h2>Launch Programmes</h2>
                </div>
                <div className='Eventservices5'>
                    <h2>Beverage Workshops</h2>
                </div>
                <div className='Eventservices6'>
                    <h2>Corporate Beverage Events</h2>
                </div>
                <div className='Eventservices7'>
                    <h2>FLR Maintenance</h2>
                </div>
                <div className='Eventservices8'>
                    <h2>International Events</h2>
                </div>
                <div className='Eventservices9'>
                    <h2>International Education</h2>
                </div>
            </div>
            <AllContact/>
        </div>
    </>
  );
}
