import React from "react";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';

class ContactUs extends React.Component
{
    render()
    {
        return <>
        <div id="ContactScreen"> 
            <h1>GET IN TOUCH</h1>
            <h3>Explore The Profession With Us</h3>
        </div>
        <div className='contactScreenDiv'> 
            {this.buildBangaloreContact()}
            {this.buildHyderbadContact()}
        </div>    
                </>    
    }

    
    buildBangaloreContact()
    {
        return <div className='detailsSection'>
                    <h1>Bengaluru</h1>
                    <div>
                    <div className="locationAddress">
                        <p className="locationIcon"><LocationOnOutlinedIcon/></p>
                        <p className="locationAdr">1/6,Opposite Karimariyamman Arch Metro Pillar No 239, near Magadi Road Metro Station Bangaluru - 560023</p>
                    </div>
                    <div className="location_Email">
                        <p className="locationIcon"><MailOutlinedIcon/></p>
                        <p className="locationEmail">universalbartendingacademy@gmail.com</p>
                    </div>
                    <div className="location_Phone">
                        <p className="locationIcon"><CallOutlinedIcon/></p>
                        <p className="locationEmail">+91 8867169696 /+91 8867269696</p>
                    </div>
                    </div>
                    <div className='embeddedMap'>
            <iframe className='ubaMap' title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.944453676535!2d77.55104817459356!3d12.975404587340323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d8d101bbe33%3A0xd1f0d9654b49a9bc!2sUniversal%20Bartending%20Academy!5e0!3m2!1sen!2sin!4v1687790477857!5m2!1sen!2sin"></iframe>
                </div>
                </div>
    }

    buildHyderbadContact()
    {
        return <div className='detailsSection'>
        <h1>Hyderabad</h1>
        <div>
        <div className="locationAddress">
            <p className="locationIcon"><LocationOnOutlinedIcon/></p>
            <p className="locationAdr">16-11-19/2/5, behind Yes Bank, Saleem Nagar Colony, Malakpet Extension, New Malakpet, Hyderabad, Telangana - 500036</p>
        </div>
        <div className="location_Email">
            <p className="locationIcon"><MailOutlinedIcon/></p>
            <p className="locationEmail">ubahyderbad@gmail.com</p>
        </div>
        <div className="location_Phone">
            <p className="locationIcon"><CallOutlinedIcon/></p>
            <p className="locationEmail">+91 9059210536 /+91 9059210836</p>
        </div>
        </div>
        <div className='embeddedMap'>
        <iframe className='ubaMap' title="location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d121814.34806510647!2d78.3630182883178!3d17.42625681906061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb99a7f3632149%3A0x381a3f52d1779a80!2sUniversal%20Bartending%20Academy%20Hyderbad!5e0!3m2!1sen!2sin!4v1693728645668!5m2!1sen!2sin"></iframe>
    </div>
    </div>
}
    }   


export default ContactUs;