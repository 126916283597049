import React from 'react';
import '../../App.css';
import AllContact from '../pages/AllContacts';
import Course_new2 from '../../Assets/CourseImages/Course_new2.jpeg';

export default function beverageService() {
  return <div id='CoursesScreen'>
  <div className='courseCards1'>
      <div className='courseCards1Img'>
          <img alt='imagr' src={Course_new2}></img>
      </div>
      <div className='courseCards1Content'>
        <h2 className='coursesHeader'>Diploma In Food And Beverage Service</h2>
          <p className='courseCardsDes'>Food & Beverage (F&B) Management is a segment of the hospitality industry that focuses on operations 
                        in restaurants, hotels, resorts, catering companies, hospitals, hotels, and more. It includes 
                        the business side of food, like ordering and inventory, managing budgets, and planning and costing menus.</p>
                        <h2 className='coursesTeach'>We teach :</h2>
                    <h3 className='courseSubHeader'>Different Types of Food and Beverage Services and History of the Food and Beverage Service Industry Introduction to Food and Beverage Service
                        Areas of Food and Beverage Service Departments</h3>
                    <ul>
                        <li>Waiter Service</li>
                        <li>Self Service</li>
                        <li>Assisted Service</li>
                        <li>Primary Catering Sectors</li>
                        <li>Secondary Catering Sectors</li>
                        <li>Commercial Sectors</li>
                        <li>Non-Captive Market</li>
                    </ul>
                    <ul>
                        <li>Captive Market</li>
                        <li>SEMI Captive Market</li>
                        <li>Banquet Organization</li>
                        <li>Room Service Organization</li>
                        <li>Lounge Organization</li>
                        <li>Bar Organization</li>       
                    </ul>
        
    </div>
    </div>
    <div className='coursesCards'>
      <div className='cards'>
          <i class="far fa-clock"></i>                        
          <h3>DURATION</h3>
          <p>1 Years</p>
      </div>
      <div className='cards'>
      <i class="fa-solid fa-certificate"></i>                  
       <h3>CERTIFICATE</h3>
          <p>We Provide International Course Certificate</p>
      </div >
      <div className='cards'>
      <i class="fa-solid fa-passport"></i>
          <h3>INTERNATIONAL PLACEMENT</h3>
          <p>We Offer Placements In National And International</p>
      </div>
    </div>
  <div>
    <AllContact/>
  </div>
 </div>
}
