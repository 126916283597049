import React from 'react';
import '../../App.css';
import AllContact from '../pages/AllContacts';
import Course_5 from '../../Assets/CourseImages/Course5.jpeg';

export default function barCourse() {
  return <div id='CoursesScreen'>
          <div className='courseCards1'>
            <div className='courseCards1Img'>
              <img alt='imagr' src={Course_5}></img>
            </div>
            <div className='courseCards1Content'>
              <h2 className='coursesHeader'>Basic Bar Course</h2>
              <p className='courseCardsDes'>Bartenders should be familiar with the products they work with. 
                              Learning about whiskies, beer styles, wine varietals, and cocktails is essential. 
                              A bartender will often be asked for recommendations, required to make create cocktails
                              on the fly, or offer food pairing choices for wine and beer.
                </p>
                <h2 className='coursesTeach'>We teach :</h2>
                <ul>
                  <li> Basic spirit knowledge </li>
                  <li>Making extensive cocktails and mock tails</li> 
                  <li>Fire flairing and juggling </li>
                  <li>Inventory</li>
                </ul>
            </div>
          </div>
                <div className='coursesCards'>
                  <div className='cards'>
                  <i class="fa-solid fa-clock"></i>                       
                      <h3>DURATION</h3>
                      <p>2 Months</p>
                  </div>
                  <div className='cards'>
                  <i class="fa-solid fa-certificate"></i>                  
                  <h3>CERTIFICATE</h3>
                      <p>We Provide International Certifications</p>
                  </div >
                  <div className='cards'>
                  <i class="fa-solid fa-passport"></i>
                      <h3>INTERNATIONAL PLACEMENT</h3>
                      <p>We Offer Placements In India And Abroad</p>
                  </div>
                </div>
              <div>
                <AllContact/>
              </div>
</div>
}
